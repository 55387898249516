import React from 'react';

// Own components
import SEO from '../components/seo';
import About from '../containers/About';
import Layout from '../components/Layout/es';

const AboutPage = () => (
  <Layout>
    <SEO title="Bienvenido" />
    <About />
  </Layout>
);

export default AboutPage;
